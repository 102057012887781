import { gql } from '@apollo/client'

const BILL_LOG_ATTRIBUTES = gql`
  fragment billLogAttributes on BillLog {
    _id
    bill_id
    bill_no
    user_id
    username
    store_id
    action
    status
    payment_type
    before_update_total
    after_update_total
    before_update_data {
      # _id
      collection_date
      bill_date
      bill_items {
        category_no
        category_name
        category_display_name
        currency
        product_id
        display_name
        name
        minimum_charges
        maximum_discount
        unit
        price
        qty
        sub_total
        discount
        total_discount
        total
      }
      bill_no
      bill_type
      customer_id
      customer_name
      customer_phone
      payment_type
      status
      total_amount
      total_discount
      round_total
      total_paid
    }
    after_update_data {
      # _id
      collection_date
      bill_date
      bill_items {
        category_no
        category_name
        category_display_name
        currency
        product_id
        display_name
        name
        minimum_charges
        maximum_discount
        unit
        price
        qty
        sub_total
        discount
        total_discount
        total
      }
      bill_no
      bill_type
      customer_id
      customer_name
      customer_phone
      payment_type
      status
      total_amount
      total_discount
      round_total
      total_paid
    }
    created_date
  }
`

export const GET_INSUFFICIENT_BILL = gql`
  query ($billDateDuration: JSON){
    getInsufficientBill(billDateDuration:$billDateDuration){
      totalResult,
      result{ ...billLogAttributes }
    }
  }${BILL_LOG_ATTRIBUTES}
`